"use client";

import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";
import { NextImage } from "@src/components";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useResponsive } from "@src/hooks";
import { useState, useMemo } from "react";
import Skeleton from "@mui/material/Skeleton";
import type { Banner } from "@src/types/home";
const useStyles = makeStyles<{
  isMobile: boolean;
}>()((_, {
  isMobile
}) => ({
  paginationBullet: {
    margin: isMobile ? "0px 4px" : "0px 10px",
    width: "10px",
    height: "10px",
    border: "1px solid #000",
    textAlign: "center",
    color: "#000",
    opacity: 1,
    background: "#fff",
    borderRadius: "50%",
    cursor: "pointer"
  },
  paginationBulletActive: {
    boxSizing: "border-box",
    background: "black",
    width: "12px",
    height: "12px"
  },
  mySwiper: {
    width: "100%",
    height: "100%",
    "& .swiper-wrapper": {
      height: "100%"
    },
    "& .swiper-pagination": {
      bottom: "20px !important",
      paddingTop: 0,
      alignItems: "center",
      position: "absolute"
    }
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%"
  }
}));
const BannerSkeleton = ({
  isMobile
}: {
  isMobile: boolean;
}) => <Skeleton variant="rectangular" animation="wave" sx={{
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  maxWidth: "1250px",
  margin: "0 auto"
}} data-sentry-element="Skeleton" data-sentry-component="BannerSkeleton" data-sentry-source-file="background-cover.tsx" />;
const BannerSlider = ({
  banners,
  isMobile,
  classes,
  onFirstImageLoad
}: {
  banners: Banner[];
  isMobile: boolean;
  classes: any;
  onFirstImageLoad: () => void;
}) => {
  const firstBanner = useMemo(() => banners[0], [banners]);
  return <Swiper pagination={{
    clickable: true,
    bulletClass: classes.paginationBullet,
    bulletActiveClass: classes.paginationBulletActive
  }} className={classes.mySwiper} modules={[Pagination, Autoplay]} autoplay={{
    delay: 5000,
    disableOnInteraction: false
  }} data-sentry-element="Swiper" data-sentry-component="BannerSlider" data-sentry-source-file="background-cover.tsx">
      {banners.map((item, index) => <SwiperSlide key={item.id}>
          <Link href={item.url || ""} prefetch={index === 0}>
            <Box className={classes.imageContainer}>
              <NextImage key={item.id} src={isMobile ? item.mobile_image?.url : item.desktop_image?.url} width={isMobile ? item.mobile_image?.width : item.desktop_image?.width} height={isMobile ? item.mobile_image?.height : item.desktop_image?.height} alt={item.title || item.description || ""} loading={index === 0 ? "eager" : "lazy"} priority={index === 0} sizes="100vw" quality={index === 0 ? 90 : 75} placeholder="blur" blurDataURL={`data:image/svg+xml;base64,${Buffer.from(`<svg width="${isMobile ? item.mobile_image?.width : item.desktop_image?.width}" height="${isMobile ? item.mobile_image?.height : item.desktop_image?.height}" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="#f3f4f6"/></svg>`).toString('base64')}`} style={{
            objectFit: "cover",
            width: '100%',
            height: '100%'
          }} onLoad={() => {
            if (index === 0) {
              onFirstImageLoad();
            }
          }} />
            </Box>
          </Link>
        </SwiperSlide>)}
    </Swiper>;
};
const BackgroundCover = ({
  banners
}: {
  banners: Banner[];
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const {
    isMobile
  } = useResponsive();
  const {
    classes
  } = useStyles({
    isMobile
  });
  if (!banners || banners.length === 0) {
    return null;
  }
  return <Box component="section" aria-label="Banner slider" sx={{
    position: "relative",
    width: "100%",
    maxWidth: isMobile ? "1250px" : "100%",
    margin: "0 auto",
    height: isMobile ? "300px" : "521px",
    overflow: "hidden",
    "& .swiper-pagination": {
      display: banners?.length > 1 ? "flex !important" : "none !important",
      justifyContent: "center",
      gap: isMobile ? "4px" : "10px"
    }
  }} data-sentry-element="Box" data-sentry-component="BackgroundCover" data-sentry-source-file="background-cover.tsx">
      {!imageLoaded && <BannerSkeleton isMobile={isMobile} />}
      {banners?.length > 0 && <BannerSlider banners={banners} isMobile={isMobile} classes={classes} onFirstImageLoad={() => setImageLoaded(true)} />}
    </Box>;
};
export default BackgroundCover;